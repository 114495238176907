import { FunctionComponent, ReactNode, RefObject } from 'react';
import { classNames } from '../../../utils/classNames';
import PikIcon from '../PikIcon/PikIcon';
import PikButtonVariant from './types/PikButtonVariant';
import PikIconColor from '../PikIcon/types/PikIconColor';
import getTextStylesForSize from '../PikText/utils/getTextStylesForSize';
import PikTooltip from '../PikTooltip/PikTooltip';
import PikIconReplace from '../PikIcon/icons/Replace/PikIconReplace';
import PikButtonProps from './types/PikButtonProps';
import { unreachable } from '../../../utils/unreachable';

const isFilledVariant = (variant: PikButtonVariant) => {
  return ['purple', 'orange', 'white'].includes(variant);
};

const PikButton: FunctionComponent<PikButtonProps> = ({
  variant,
  size,
  id,
  onClick,
  disabled,
  children,
  inputType = 'button',
  leftIconSource,
  rightIconSource,
  loading = false,
  fullWidth = false,
  disabledTooltip: givenDisabledTooltip,
  disabledTooltipDirection = 'top-center',
  buttonRef,
  polling,
  sentryMask = false,
  missingFields,
}) => {
  const effectiveDisabled = disabled || (missingFields || []).length > 0;
  const anyDisablingEffect = effectiveDisabled || loading;
  const variantClassNames: string[] = [];
  if (variant === 'purple') {
    variantClassNames.push('bg-pik-night text-pik-white');
    if (!anyDisablingEffect) {
      variantClassNames.push('hover:bg-pik-night-dark');
    }
  } else if (variant === 'orange') {
    variantClassNames.push('bg-pik-terracotta text-pik-white');
    if (!anyDisablingEffect) {
      variantClassNames.push('hover:bg-pik-terracotta-dark');
    }
  } else if (variant === 'white') {
    variantClassNames.push('bg-pik-white text-slate-I');
    if (!anyDisablingEffect) {
      variantClassNames.push('hover:bg-pik-neutral-50');
    }
  } else if (variant === 'text') {
    variantClassNames.push(
      'underline underline-offset-[6px] border-1 border-pik-purple-product text-pik-purple-product'
    );
    if (!anyDisablingEffect) {
      variantClassNames.push('hover:border-pik-purple-product-dark hover:text-pik-purple-product-dark');
    }
  } else if (variant === 'gray-text') {
    variantClassNames.push(
      'underline underline-offset-[6px] border-1 border-pik-neutral-400 text-pik-neutral-400'
    );
    if (!anyDisablingEffect) {
      variantClassNames.push('hover:border-pik-neutral-700 hover:text-pik-neutral-700');
    }
  }

  if (isFilledVariant(variant)) {
    if (variant !== 'white') {
      variantClassNames.push('px-[18px]');
      variantClassNames.push('rounded-[4px]');
      variantClassNames.push('button-clip-path');
    } else {
      variantClassNames.push('px-[20px]');
      variantClassNames.push('rounded-[3px]');
      variantClassNames.push('inner-button-clip-path');
    }
  }

  const disabledTooltip = (() => {
    if (disabled && givenDisabledTooltip !== undefined) {
      return givenDisabledTooltip;
    } else if (missingFields !== undefined && missingFields.length > 0) {
      return (
        `Missing Field${missingFields.length > 1 ? 's' : ''}:\n` +
        missingFields.map((field) => ` - ${field.fieldName}`).join('\n')
      );
    } else {
      return undefined;
    }
  })();
  let disabledClassNames = '';
  if (anyDisablingEffect) {
    const sharedDisabledClassNames = 'opacity-50';
    if (loading) {
      disabledClassNames = classNames(sharedDisabledClassNames, 'cursor-wait');
    } else if (effectiveDisabled) {
      disabledClassNames = classNames(sharedDisabledClassNames, 'cursor-not-allowed');
    }
  }

  const sizeClassNames = [];
  if (isFilledVariant(variant)) {
    if (variant !== 'white') {
      if (size === 'large') {
        sizeClassNames.push('h-[48px]');
      } else if (size === 'medium') {
        sizeClassNames.push('h-[40px]');
      } else if (size === 'small') {
        sizeClassNames.push('h-[36px]');
      } else if (size === 'x-small') {
        sizeClassNames.push('h-[32px]');
      }
    } else {
      if (size === 'large') {
        sizeClassNames.push('h-[46px]');
      } else if (size === 'medium') {
        sizeClassNames.push('h-[38px]');
      } else if (size === 'small') {
        sizeClassNames.push('h-[34px]');
      } else if (size === 'x-small') {
        sizeClassNames.push('h-[30px]');
      }
    }
  }

  switch (size) {
    case 'large':
      sizeClassNames.push(getTextStylesForSize('16'));
      break;
    case 'medium':
      sizeClassNames.push(getTextStylesForSize('14'));
      break;
    case 'small':
      sizeClassNames.push(getTextStylesForSize('14'));
      break;
    case 'x-small':
      sizeClassNames.push(getTextStylesForSize('12'));
      break;
    default:
      unreachable(size);
  }

  const iconClassNames = [];
  let leftIconContent = null;
  let rightIconContent = null;

  if (polling !== undefined) {
    if (leftIconSource !== undefined) {
      throw new Error('Can not have a left icon while polling');
    }
    leftIconSource = PikIconReplace;
  }

  if (leftIconSource !== undefined || rightIconSource !== undefined) {
    iconClassNames.push('flex flex-row items-center');
    let iconColor: PikIconColor;
    if (isFilledVariant(variant) && variant !== 'white') {
      iconColor = 'light';
      iconClassNames.push('pl-[16px]');
    } else if (isFilledVariant(variant)) {
      iconColor = 'dark';
      iconClassNames.push('pl-[16px]');
    } else {
      iconColor = 'inherit';
    }

    if (leftIconSource !== undefined) {
      if (isFilledVariant(variant)) {
        iconClassNames.push('pl-[16px]');
      }

      leftIconContent = (
        <>
          <div className={`${polling ? 'animate-spin' : ''}`}>
            <PikIcon iconSource={leftIconSource} color={iconColor} />
          </div>
          <div className='w-[10px] h-full' />
        </>
      );
    }

    if (rightIconSource !== undefined) {
      if (isFilledVariant(variant)) {
        iconClassNames.push('pr-[16px]');
      }

      rightIconContent = (
        <>
          <div className='w-[10px] h-full' />
          <PikIcon iconSource={rightIconSource} color={iconColor} />
        </>
      );
    }
  }

  let fullWidthProps = '';
  if (fullWidth && variant !== 'text') {
    fullWidthProps = 'w-full';
  }

  const focusVisibleClassNames = 'focus-visible:outline-none focus-visible:ring-0';

  const allClassNames = classNames(
    iconClassNames.join(' '),
    variantClassNames.join(' '),
    disabledClassNames,
    sizeClassNames.join(' '),
    fullWidthProps,
    focusVisibleClassNames
  );

  const buttonContent = (
    <button
      ref={buttonRef}
      aria-label={children}
      aria-disabled={anyDisablingEffect}
      id={id}
      type={inputType}
      onClick={() => {
        if (anyDisablingEffect) {
          if (missingFields !== undefined) {
            for (const missingField of missingFields) {
              missingField.onMissingClick && missingField.onMissingClick();
              missingField.inputRef && missingField.inputRef.current?.highlightError();
            }
          }

          return;
        }

        onClick && onClick();
      }}
      className={allClassNames}
    >
      {leftIconContent}
      {children}
      {rightIconContent}
    </button>
  );

  let contentForTooltip: ReactNode;
  if (variant !== 'white') {
    contentForTooltip = buttonContent;
  } else {
    // borders don't work with clip paths because they're rendered before the clip path
    // is applied. Our hack is to render the inner box 1px smaller in each direction, and
    // render it inside a box of normal button size, with the background color of our "border"

    let outerBoxSizeClassNames = '';
    if (size === 'large') {
      outerBoxSizeClassNames = 'h-[48px]';
    } else if (size === 'medium') {
      outerBoxSizeClassNames = 'h-[40px]';
    } else if (size === 'small') {
      outerBoxSizeClassNames = 'h-[36px]';
    } else if (size === 'x-small') {
      outerBoxSizeClassNames = 'h-[32px]';
    }

    let outerBoxHoverClassNames = '';
    if (!effectiveDisabled && !loading) {
      outerBoxHoverClassNames = 'hover:bg-pik-night-dark';
    }

    contentForTooltip = (
      <div
        className={classNames(
          'bg-pik-neutral-200 p-[1px] rounded-[4px] button-clip-path inline-block flex-none',
          outerBoxSizeClassNames,
          outerBoxHoverClassNames,
          fullWidthProps
        )}
      >
        {buttonContent}
      </div>
    );
  }

  const outerFocusVisibleClassNames =
    '[&:has(:focus-visible)]:outline [&:has(:focus-visible)]:outline-2 [&:has(:focus-visible)]:outline-pik-purple-product [&:has(:focus-visible)]:outline-offset-4 [&:has(:focus-visible)]:rounded-[1px]';

  if (effectiveDisabled && disabledTooltip !== undefined && disabledTooltip !== '') {
    return (
      <PikTooltip content={disabledTooltip} direction={disabledTooltipDirection}>
        {/* 
          Using the tooltip's ref feature so that the tooltip container's 
          styling doesn't mess with our button's styling. PikButton has control
          over the container div used here.
        */}
        {(ref: RefObject<HTMLDivElement>) => (
          <div
            ref={ref}
            className={classNames(
              fullWidth ? 'w-full flex-col items-center' : '',
              outerFocusVisibleClassNames
            )}
            {...(sentryMask ? { 'data-sentry-mask': true } : {})}
          >
            {contentForTooltip}
          </div>
        )}
      </PikTooltip>
    );
  } else {
    return (
      <div
        className={classNames(
          fullWidth ? 'w-full flex flex-col items-center' : '',
          `flex-none h-fit w-fit`,
          outerFocusVisibleClassNames
        )}
        {...(sentryMask ? { 'data-sentry-mask': true } : {})}
      >
        {contentForTooltip}
      </div>
    );
  }
};

export default PikButton;
